import styled from 'styled-components'

const Subtitle = styled.h2`
  color: #016350;
  font-family: 'Circular Spotify Head';
  letter-spacing: 1.6px;
  line-height: 2rem;
  margin-top: 30px;
`

export default Subtitle
