import React from "react"
import { Helmet } from "react-helmet"
import * as gtag from "../analytics/gtag"

import Layout from "../components/layout"
import Title from "../components/atoms/title"
import Subtitle from "../components/atoms/subtitle"
import Text from "../components/atoms/text"

const IndexPage = () => {
  React.useEffect(() => {
    gtag.pageview(window.location.href)
  }, [])

  return (
    <Layout>
      <Helmet>
        <meta
          property="og:url"
          content="https://escutaaasminas.byspotify.com/"
        />
        <meta property="og:type" content="Terms" />
        <meta
          property="og:title"
          content="Termos de Participação | Escuta as Minas"
        />
        <title>Termos de Participação | Escuta as Minas</title>
        <meta
          name="keywords"
          content="Casa de Música, Casa Spotify, estúdio spotify, Spotify Mulheres, Escuta as Minas, casa de música escuta as minas, Natura Musical, #escutaaasminas, música empoderamento feminino , concurso spotify, concurso cantoras, nextup youtube, edital spotify"
        />
        <meta
          property="og:description"
          content="A Casa de Música Escuta As Minas do Spotify, feita POR mulheres e PARA mulheres, nasceu pra facilitar a troca entre artistas ascensão com outras profissionais mulheres e tem inscrições abertas para escolha de mais 12 artistas até dia 28/08/2019."
        />
        <meta property="og:image" content="cover.png" />
      </Helmet>

      <Title style={{ marginBottom: 0, marginTop: 50 }}>
        Termos de participação
      </Title>

      <Subtitle>Visão Geral</Subtitle>
      <Text>
        O Programa “Spotify – Escuta As Minas” (“<strong>Programa</strong>”) é
        designado para apoiar artistas femininas emergentes no Brasil, e a lhes
        fornecer ferramentas e tutoria para desenvolver as suas carreiras
        musicais. Estes Termos de Participação (“<strong>Termos”</strong>)
        descrevem como o <strong>Programa</strong> funciona e o que você deve
        fazer caso deseje se candidatar ao <strong>Programa</strong>. Como
        descrito abaixo, um grupo de candidatas será selecionado para participar
        de um programa durante 06 (seis) meses em São Paulo, incluindo uma
        semana de visitas ao Estúdio “Escuta As Minas”, onde as candidatas
        trabalharão com música original mediante a assistência de produtoras e
        tutoras selecionadas pelo Spotify.
      </Text>

      <Subtitle>Acordo Para os Termos de Participação</Subtitle>
      <Text>
        Ao submeter a candidatura, você confirma que leu e concorda com todos
        estes Termos e, ao concordar com estes Termos, aceita todas as decisões
        do Spotify relativas ao Programa, que serão finais. Não se inscreva caso
        discorde, total ou parcialmente, destes Termos.
      </Text>

      <Subtitle>Elegibilidade</Subtitle>
      <Text>
        Para participar do Programa, você deve residir em São Paulo, Capital ou
        na Grande São Paulo, ter pelo menos 18 anos de idade, bem como ter a
        capacidade de aceitar estes Termos e contratos adicionais que serão
        requisitados para a participação no Programa. O Programa não é aberto a
        pessoas que trabalham para o Spotify ou que são membros de domicílio ou
        da família imediata de qualquer funcionário do Spotify.
      </Text>

      <Subtitle>Processo de Candidatura e Prazo</Subtitle>
      <Text>
        O Spotify aceitará candidaturas até 28/08. Para se inscrever, visite
        https://escutaaasminas.byspotify.com/ e siga as instruções. Como parte
        do processo de inscrição, você será solicitada a responder breves
        questões. Cada candidata pode se inscrever uma única vez.
      </Text>

      <Subtitle>Seleção das Candidatas</Subtitle>
      <Text>
        O Spotify selecionará e convidará 12 (doze) candidatas para participar
        do Programa, baseando-se na qualidade de sua música, na originalidade,
        na cobertura de gênero musical, no potencial e na paixão, conforme
        determinado pelo Spotify ao analisar as candidaturas. Todas as decisões
        serão tomadas pelo Spotify e membros de seu conselho externo, ao livre
        critério destes. O Spotify não é obrigado a selecionar qualquer número
        específico de candidatas para convidá-las a participar do Programa. Se,
        a qualquer momento, durante o processo seletivo, o Spotify determinar,
        conforme o seu livre critério, que a qualidade e a variedade das
        candidaturas não atingiu o previsto pelo Spotify, então este terá o
        direito de modificar ou de cancelar o Programa.
      </Text>

      <Subtitle>Verificação da Candidata</Subtitle>
      <Text>
        O Spotify contatará a candidata selecionada utilizando as informações de
        contato informadas na inscrição. Cada candidata selecionada deverá
        responder em até 07 (sete) dias. Para participar, cada candidata
        selecionada poderá ser solicitada a assinar uma liberação de aparência
        ou outros documentos. As candidatas selecionadas que completarem o
        processo de verificação dentro do período requisitado serão, desta
        forma, convidadas a participar do Programa.
      </Text>
      <Text>
        Cada candidata selecionada deverá responder em até 07 (sete) dias. Para
        participar, cada candidata selecionada poderá ser solicitada a assinar
        uma liberação de direitos de imagem e/ou outros documentos como parte de
        sua participação no Programa. Estas candidatas selecionadas que
        completarem o processo de verificação dentro do período requisitado
        serão, desta forma, convidadas para participar do Programa (“
        <strong>Participante”</strong>).
      </Text>

      <Subtitle>Comportamento durante a Participação do Programa</Subtitle>
      <Text>
        Você concorda em não cometer qualquer ato ou fazer qualquer coisa que
        possa ser razoavelmente considerado como: (i) imoral, enganoso,
        escandaloso, ou obsceno; ou (ii) injuriar, macular, prejudicar, ou de
        qualquer outra forma afetar negativamente a reputação, o nome e o bom
        prestígio do Spotify. Caso a sua conduta viole esta disposição, o
        Spotify, ao seu livre critério, terá o direito de encerrar o seu
        envolvimento com o Programa.
      </Text>

      <Subtitle>Viagens e acomodações</Subtitle>
      <Text>
        Participantes que vivem fora da área metropolitana de São Paulo não
        terão o fornecimento de transporte para e de São Paulo, como também não
        receberão hospedagem durante o Programa. Todos os detalhes com viagens,
        refeições e acomodações não serão fornecidos pelo Spotify.
      </Text>

      <Subtitle>Produção do Spotify/Direitos de Distribuição</Subtitle>
      <Text>
        O Spotify não terá quaisquer direitos sobre as músicas criadas pelas
        participantes nos estúdios Escuta As Minas. Igualmente, a distribuição
        de qualquer música criada pelas candidatas fica a cargo da artista.
      </Text>

      <Subtitle>Garantia de Direitos Promocionais</Subtitle>
      <Text>
        O Spotify terá o direito de filmar, gravar, fotografar, ou de qualquer
        outro modo registrar todas as partes do Programa, e pode permitir que
        jornalistas, a mídia em geral e outros façam o mesmo. Ao aplicar, você
        concorda que, se participar do Programa, o Spotify terá o direito de
        usar o seu nome, imagem e som de voz, bem como todos os materiais
        preenchidos por você em relação à sua candidatura no Programa para
        comercializar, promover e divulgar o Programa e/ou o Spotify.
      </Text>

      <Subtitle>Conteúdo da sua candidatura</Subtitle>
      <Text>
        Ao preencher a sua candidatura, você declara e garante que os materiais
        de sua candidatura são verdadeiros e precisos em todos os sentidos, que
        você detém todos os materiais enviados como parte de sua candidatura,
        que o conceito da sua canção é original e que você é a detentora de
        todos os direitos necessários para participar do Programa e para
        desenvolver futuramente o(s) conceito(s) de sua canção como parte do
        Programa. Você concorda em não enviar qualquer candidatura ou material
        que (i) viole quaisquer direitos de autor, marca, direitos contratuais
        ou outras propriedades de titularidade de quaisquer terceiros.
      </Text>

      <Subtitle>Compensação</Subtitle>
      <Text>
        Você concorda que não terá direito a nenhuma compensação com relação à
        sua candidatura e, caso selecionada, com a sua participação no Programa.
      </Text>

      <Subtitle>Isenção de Responsabilidade e Assunção de Riscos</Subtitle>
      <Text>
        Ao enviar sua candidatura ou participar do Programa, você, em nome de
        seus herdeiros, representantes pessoais, responsáveis e cessionários,
        voluntária e conscientemente, libera completamente e para sempre o
        Spotify, toda e cada uma das empresas de seu grupo econômico, afiliadas
        e subsidiárias, cada um dos seus respectivos sucessores e diretores,
        executivos, funcionários, cessionários, e todas as pessoas e entidades
        envolvidas no desenvolvimento ou na execução do Programa (todas as
        anteriores ora definidas como “<strong>Partes Liberadas</strong>”), de e
        contra qualquer e todo tipo de reclamação, demanda, responsabilidade, ou
        causa de ação de qualquer tipo ou natureza, conhecida ou desconhecida,
        que a Participante tenha tido, no presente ou que possa vir a surgir no
        futuro, de qualquer espécie ou relacionada ao Programa ou qualquer
        Proposta ou exercício pelo Spotify dos direitos concedidos a este. O
        Spotify não é responsável por capturas incorretas ou imprecisas, danos
        ou perda de candidaturas ou qualquer outro erro humano ou técnico de
        qualquer forma relacionados à candidatura ao Programa.
      </Text>

      <Subtitle>Legislação Aplicável</Subtitle>
      <Text>
        Este termo será regido pelas Leis do Brasil. Foros Estaduais ou Federais
        localizados na Cidade de São Paulo terão jurisdição exclusiva sobre
        quaisquer disputas relacionadas a estes Termos.
      </Text>
    </Layout>
  )
}

export default IndexPage
